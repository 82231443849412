<template>
  <div>
    <NuxtLoadingIndicator color="#1e1e1e" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
onMounted(() => {
  // Change the locale of PrimeVue components
  // For some reason it works buggy in the plugin
  const { changePrimeLocale } = usePrimeVueLocale()
  const cookieLang = useCookie('lang')
  changePrimeLocale(cookieLang.value ?? 'is')
})
</script>
