import type { User } from '~/types/user'

interface UserState {
  user: User | null
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: null
  }),
  actions: {
    async fetchAuthUser() {
      if (!this.user) {
        const resp = await $api<User>('/auth/user/')
        this.user = resp
      }

      return this.user
    }
  }
})
