interface TokenResponse {
  access: string
  refresh: string
}
interface AuthState {
  loggedIn: boolean
  email: string
  phoneNumber: string
  error: string
  emailVisible: boolean
  controlCode: string
  token: string
}
interface MobileLoginResponse {
  control_code: string
  token: string
}
interface CreateDokoSessionResponse {
  status: string
  session_token: string
  url: string
  expires_in: number
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    loggedIn: false,
    email: '',
    phoneNumber: '',
    error: '',
    emailVisible: false,
    controlCode: '',
    token: ''
  }),
  actions: {
    // Internal
    clearStore() {
      this.email = ''
      this.phoneNumber = ''
      this.error = ''
      this.controlCode = ''
      this.token = ''
      this.emailVisible = false
    },
    handleMobileLoginError(error: any) {
      if (error?.status === 400) {
        if (error.data.code === 'phone_not_found_on_user') {
          this.emailVisible = true
          this.error = 'auth-phone-not-found-on-user'
        } else if (error.data.code === 'phone_found_not_primary') {
          this.error = 'auth-phone-found-not-primary'
        } else if (error.data.code === 'email_exists') {
          this.error = 'auth-email-exists'
        } else if (error.data.code === 'authentication_server_error') {
          this.error = 'auth-authentication-server-error'
        } else if (error.data.code === 'doko') {
          this.error = error.data.message
        }
      }
      this.controlCode = ''
    },
    async afterLoginProcess(data: TokenResponse | null) {
      const access = useCookie('auth.access')
      const refresh = useCookie('auth.refresh')

      access.value = data?.access ?? null
      refresh.value = data?.refresh ?? null

      this.loggedIn = true
      const { fetchAuthUser } = useUserStore()
      await nextTick(fetchAuthUser)
    },
    logout() {
      const access = useCookie('auth.access')
      const refresh = useCookie('auth.refresh')
      const { user } = storeToRefs(useUserStore())
      access.value = null
      refresh.value = null
      this.loggedIn = false
      user.value = null
    },

    // External
    async passwordLogin(password: string) {
      try {
        const resp = await $api<TokenResponse>('token/', {
          method: 'post',
          body: {
            email: this.email,
            password
          }
        })
        this.clearStore()
        await this.afterLoginProcess(resp)
      } catch (error: any) {
        if (error.data?.code === 'invalid_credentials') {
          this.error = 'auth-invalid-credentials'
        } else {
          this.error = 'auth-something-wrong'
        }
        throw error
      }
    },
    async fetchNewToken() {
      const refreshToken = useCookie('auth.refresh')
      const { BASE_URL } = useRuntimeConfig().public

      // Using $fetch to make a request without auth headers
      const resp = await $fetch<Pick<TokenResponse, 'access'>>(
        'token/refresh/',
        {
          baseURL: BASE_URL,
          method: 'post',
          body: {
            refresh: refreshToken.value
          }
        }
      )
      const access = useCookie('auth.access')
      access.value = resp?.access ?? null
    },
    async checkEmail() {
      const resp = await $api(`emails/${this.email}/`)
      return resp
    },
    async forgotPasswordEmail(email?: string) {
      try {
        await $api(`forgot-password/${email ?? this.email}/`)
      } catch (error: any) {
        if (error.status === 404) {
          this.error = 'auth-forgot-password-no-email'
        }
        if (error.status === 400) {
          if (error.data.error) {
            this.error = 'auth-forgot-password-failed-send'
          }
        }
        throw error
      }
    },
    async createPasswordUser(payload: { full_name: string; password: string }) {
      const resp = await $api<TokenResponse>('users/', {
        method: 'post',
        body: {
          email: this.email,
          ...payload
        }
      })
      await this.afterLoginProcess(resp)
    },
    async mobileLogin() {
      try {
        const resp = await $api<MobileLoginResponse>('e-sign/mobile-login/', {
          method: 'post',
          body: {
            phone: this.phoneNumber,
            ...(this.email ? { email: this.email } : {})
          }
        })
        this.error = ''
        this.controlCode = resp.control_code
        this.token = resp.token

        await this.mobileLoginStatus()
      } catch (error: any) {
        this.handleMobileLoginError(error)
        throw error
      }
    },
    async mobileLoginStatus() {
      const resp = await $api<TokenResponse>(
        `e-sign/mobile-login/status/${this.token}/`
      )
      this.clearStore()
      await this.afterLoginProcess(resp)
    },

    async createDokoSession(returnUrl: string) {
      const origin = document.location.origin
      const resp = await $api<CreateDokoSessionResponse>(
        `e-sign/doko/create-session/`,
        {
          method: 'post',
          body: {
            return_url: returnUrl,
            origin_host: origin
          }
        }
      )
      return resp
    },
    async getDokoStatus(token: string) {
      try {
        const url = `e-sign/doko/status/${token ?? this.token}/`
        const resp = await $api<TokenResponse>(url)
        this.clearStore()
        await this.afterLoginProcess(resp)
      } catch (error: any) {
        if (error?.data?.code === 'user_not_found') {
          throw new Error('user_not_found')
        }
        this.handleMobileLoginError(error)
      }
    },
    async createDokoUser() {
      try {
        const resp = await $api<TokenResponse>('e-sign/doko/create-user/', {
          method: 'post',
          body: {
            email: this.email,
            token: this.token
          }
        })
        this.clearStore()
        await this.afterLoginProcess(resp)
      } catch (error) {
        this.handleMobileLoginError(error)
      }
    }
  }
})
