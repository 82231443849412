import { default as indexXsMLTdqYWEMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/contracts/index.vue?macro=true";
import { default as cssYgVIHfYbKsMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/[id]/css.vue?macro=true";
import { default as indexVKjnsrgjcZMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/[id]/index.vue?macro=true";
import { default as previewXjOXYcJ7bVMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/[id]/preview.vue?macro=true";
import { default as variables4R1b31DbwPMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/[id]/variables.vue?macro=true";
import { default as _91id_93U1DObvL2x5Meta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/[id].vue?macro=true";
import { default as index9mddjIubgYMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/index.vue?macro=true";
import { default as newzDvd1VCKnyMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/new.vue?macro=true";
import { default as expired_45tokenciXwQpjux8Meta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/expired-token.vue?macro=true";
import { default as indexZLiYRWr8EpMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/index.vue?macro=true";
import { default as loginfImv1dEY6uMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/login.vue?macro=true";
import { default as _91id_93vWduFqZT6GMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/sign-contract/[id].vue?macro=true";
import { default as _91tag_93q8hOJnLEH4Meta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/templates/[tag].vue?macro=true";
import { default as index6yGFgLV4TdMeta } from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/templates/index.vue?macro=true";
export default [
  {
    name: "contracts",
    path: "/contracts",
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/contracts/index.vue")
  },
  {
    name: _91id_93U1DObvL2x5Meta?.name,
    path: "/documents/:id()",
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/[id].vue"),
    children: [
  {
    name: "documents-id-css",
    path: "css",
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/[id]/css.vue")
  },
  {
    name: "documents-id",
    path: "",
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/[id]/index.vue")
  },
  {
    name: "documents-id-preview",
    path: "preview",
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/[id]/preview.vue")
  },
  {
    name: "documents-id-variables",
    path: "variables",
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/[id]/variables.vue")
  }
]
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/index.vue")
  },
  {
    name: "documents-new",
    path: "/documents/new",
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/documents/new.vue")
  },
  {
    name: "expired-token",
    path: "/expired-token",
    meta: expired_45tokenciXwQpjux8Meta || {},
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/expired-token.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexZLiYRWr8EpMeta || {},
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginfImv1dEY6uMeta || {},
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/login.vue")
  },
  {
    name: "sign-contract-id",
    path: "/sign-contract/:id()",
    meta: _91id_93vWduFqZT6GMeta || {},
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/sign-contract/[id].vue")
  },
  {
    name: "templates-tag",
    path: "/templates/:tag()",
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/templates/[tag].vue")
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/pages/templates/index.vue")
  }
]