import { jwtDecode } from 'jwt-decode'

export default defineNuxtPlugin(async () => {
  const authStore = useAuthStore()
  const { loggedIn } = storeToRefs(authStore)
  const { fetchAuthUser } = useUserStore()

  const accessToken = useCookie('auth.access')
  const refreshToken = useCookie('auth.refresh')

  try {
    if (!accessToken.value || !refreshToken.value) {
      throw new Error('No token found')
    }

    const accessDecoded = jwtDecode(accessToken.value)

    if (!accessDecoded?.exp) {
      throw new Error('Invalid token')
    }

    if (accessDecoded.exp < Date.now() / 1000 + 10) {
      const refreshDecoded = jwtDecode(refreshToken.value)

      if (!refreshDecoded?.exp) {
        throw new Error('Invalid token')
      }

      if (refreshDecoded.exp < Date.now() / 1000) {
        // refresh token is expired
        throw new Error('Refresh expired')
      }

      // refresh token alive, try to refresh
      await authStore.fetchNewToken()
    }

    await fetchAuthUser()
    loggedIn.value = true
  } catch {
    authStore.logout()
  }
})
