import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPAutoComplete, LazyPCalendar, LazyPCascadeSelect, LazyPCheckbox, LazyPCheckboxGroup, LazyPChips, LazyPColorPicker, LazyPDatePicker, LazyPDropdown, LazyPFloatLabel, LazyPFluid, LazyPIconField, LazyPIftaLabel, LazyPInputChips, LazyPInputGroup, LazyPInputGroupAddon, LazyPInputIcon, LazyPInputMask, LazyPInputNumber, LazyPInputOtp, LazyPInputSwitch, LazyPInputText, LazyPKnob, LazyPListbox, LazyPMultiSelect, LazyPPassword, LazyPRadioButton, LazyPRadioButtonGroup, LazyPRating, LazyPSelect, LazyPSelectButton, LazyPSlider, LazyPTextarea, LazyPToggleButton, LazyPToggleSwitch, LazyPTreeSelect, LazyPButton, LazyPButtonGroup, LazyPSpeedDial, LazyPSplitButton, LazyPColumn, LazyPRow, LazyPColumnGroup, LazyPDataTable, LazyPDataView, LazyPOrderList, LazyPOrganizationChart, LazyPPaginator, LazyPPickList, LazyPTree, LazyPTreeTable, LazyPTimeline, LazyPVirtualScroller, LazyPAccordion, LazyPAccordionPanel, LazyPAccordionHeader, LazyPAccordionContent, LazyPAccordionTab, LazyPCard, LazyPDeferredContent, LazyPDivider, LazyPFieldset, LazyPPanel, LazyPScrollPanel, LazyPSplitter, LazyPSplitterPanel, LazyPStepper, LazyPStepList, LazyPStep, LazyPStepItem, LazyPStepPanels, LazyPStepPanel, LazyPTabView, LazyPTabs, LazyPTabList, LazyPTab, LazyPTabPanels, LazyPTabPanel, LazyPToolbar, LazyPConfirmDialog, LazyPConfirmPopup, LazyPDialog, LazyPDrawer, LazyPDynamicDialog, LazyPOverlayPanel, LazyPPopover, LazyPSidebar, LazyPFileUpload, LazyPBreadcrumb, LazyPContextMenu, LazyPMenu, LazyPMenubar, LazyPMegaMenu, LazyPPanelMenu, LazyPSteps, LazyPTabMenu, LazyPTieredMenu, LazyPMessage, LazyPInlineMessage, LazyPCarousel, LazyPGalleria, LazyPImage, LazyPImageCompare, LazyPAvatar, LazyPAvatarGroup, LazyPBadge, LazyPBlockUI, LazyPChip, LazyPInplace, LazyPMeterGroup, LazyPOverlayBadge, LazyPScrollTop, LazyPSkeleton, LazyPProgressBar, LazyPProgressSpinner, LazyPTag, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["PAutoComplete", LazyPAutoComplete],
["PCalendar", LazyPCalendar],
["PCascadeSelect", LazyPCascadeSelect],
["PCheckbox", LazyPCheckbox],
["PCheckboxGroup", LazyPCheckboxGroup],
["PChips", LazyPChips],
["PColorPicker", LazyPColorPicker],
["PDatePicker", LazyPDatePicker],
["PDropdown", LazyPDropdown],
["PFloatLabel", LazyPFloatLabel],
["PFluid", LazyPFluid],
["PIconField", LazyPIconField],
["PIftaLabel", LazyPIftaLabel],
["PInputChips", LazyPInputChips],
["PInputGroup", LazyPInputGroup],
["PInputGroupAddon", LazyPInputGroupAddon],
["PInputIcon", LazyPInputIcon],
["PInputMask", LazyPInputMask],
["PInputNumber", LazyPInputNumber],
["PInputOtp", LazyPInputOtp],
["PInputSwitch", LazyPInputSwitch],
["PInputText", LazyPInputText],
["PKnob", LazyPKnob],
["PListbox", LazyPListbox],
["PMultiSelect", LazyPMultiSelect],
["PPassword", LazyPPassword],
["PRadioButton", LazyPRadioButton],
["PRadioButtonGroup", LazyPRadioButtonGroup],
["PRating", LazyPRating],
["PSelect", LazyPSelect],
["PSelectButton", LazyPSelectButton],
["PSlider", LazyPSlider],
["PTextarea", LazyPTextarea],
["PToggleButton", LazyPToggleButton],
["PToggleSwitch", LazyPToggleSwitch],
["PTreeSelect", LazyPTreeSelect],
["PButton", LazyPButton],
["PButtonGroup", LazyPButtonGroup],
["PSpeedDial", LazyPSpeedDial],
["PSplitButton", LazyPSplitButton],
["PColumn", LazyPColumn],
["PRow", LazyPRow],
["PColumnGroup", LazyPColumnGroup],
["PDataTable", LazyPDataTable],
["PDataView", LazyPDataView],
["POrderList", LazyPOrderList],
["POrganizationChart", LazyPOrganizationChart],
["PPaginator", LazyPPaginator],
["PPickList", LazyPPickList],
["PTree", LazyPTree],
["PTreeTable", LazyPTreeTable],
["PTimeline", LazyPTimeline],
["PVirtualScroller", LazyPVirtualScroller],
["PAccordion", LazyPAccordion],
["PAccordionPanel", LazyPAccordionPanel],
["PAccordionHeader", LazyPAccordionHeader],
["PAccordionContent", LazyPAccordionContent],
["PAccordionTab", LazyPAccordionTab],
["PCard", LazyPCard],
["PDeferredContent", LazyPDeferredContent],
["PDivider", LazyPDivider],
["PFieldset", LazyPFieldset],
["PPanel", LazyPPanel],
["PScrollPanel", LazyPScrollPanel],
["PSplitter", LazyPSplitter],
["PSplitterPanel", LazyPSplitterPanel],
["PStepper", LazyPStepper],
["PStepList", LazyPStepList],
["PStep", LazyPStep],
["PStepItem", LazyPStepItem],
["PStepPanels", LazyPStepPanels],
["PStepPanel", LazyPStepPanel],
["PTabView", LazyPTabView],
["PTabs", LazyPTabs],
["PTabList", LazyPTabList],
["PTab", LazyPTab],
["PTabPanels", LazyPTabPanels],
["PTabPanel", LazyPTabPanel],
["PToolbar", LazyPToolbar],
["PConfirmDialog", LazyPConfirmDialog],
["PConfirmPopup", LazyPConfirmPopup],
["PDialog", LazyPDialog],
["PDrawer", LazyPDrawer],
["PDynamicDialog", LazyPDynamicDialog],
["POverlayPanel", LazyPOverlayPanel],
["PPopover", LazyPPopover],
["PSidebar", LazyPSidebar],
["PFileUpload", LazyPFileUpload],
["PBreadcrumb", LazyPBreadcrumb],
["PContextMenu", LazyPContextMenu],
["PMenu", LazyPMenu],
["PMenubar", LazyPMenubar],
["PMegaMenu", LazyPMegaMenu],
["PPanelMenu", LazyPPanelMenu],
["PSteps", LazyPSteps],
["PTabMenu", LazyPTabMenu],
["PTieredMenu", LazyPTieredMenu],
["PMessage", LazyPMessage],
["PInlineMessage", LazyPInlineMessage],
["PCarousel", LazyPCarousel],
["PGalleria", LazyPGalleria],
["PImage", LazyPImage],
["PImageCompare", LazyPImageCompare],
["PAvatar", LazyPAvatar],
["PAvatarGroup", LazyPAvatarGroup],
["PBadge", LazyPBadge],
["PBlockUI", LazyPBlockUI],
["PChip", LazyPChip],
["PInplace", LazyPInplace],
["PMeterGroup", LazyPMeterGroup],
["POverlayBadge", LazyPOverlayBadge],
["PScrollTop", LazyPScrollTop],
["PSkeleton", LazyPSkeleton],
["PProgressBar", LazyPProgressBar],
["PProgressSpinner", LazyPProgressSpinner],
["PTag", LazyPTag],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
