import { jwtDecode } from 'jwt-decode'
import { defu } from 'defu'

// Function to retrieve data from API out of the nuxt context
export function $api<T>(
  request: Parameters<typeof $fetch<T>>[0],
  options?: Parameters<typeof $fetch<T>>[1]
) {
  const config = useRuntimeConfig()
  const authStore = useAuthStore()

  const defaults: Parameters<typeof $fetch<T>>[1] = {
    baseURL: config.public.BASE_URL,
    retry: 1,
    retryStatusCodes: [401, 408, 409, 425, 429, 500, 502, 503, 504],

    onRequest({ options }) {
      // Set Authorization header
      const accessToken = useCookie('auth.access')
      if (accessToken.value) {
        options.headers.set('Authorization', `Bearer ${accessToken.value}`)
      } else {
        options.headers.delete('Authorization')
      }
    },

    async onResponseError({ response }) {
      if (response?._data?.code === 'token_expired') {
        // Refresh token
        const refreshToken = useCookie('auth.refresh')
        try {
          if (!refreshToken.value) {
            throw new Error('No refresh token')
          }
          const refreshDecoded = jwtDecode(refreshToken.value)
          if (!refreshDecoded.exp || refreshDecoded.exp < Date.now() / 1000) {
            throw new Error('Invalid refresh token')
          }
          await authStore.fetchNewToken()
        } catch {
          const router = useRouter()
          authStore.logout()
          router.push('/')
        }
      }
    }
  }

  // for nice deep defaults
  const params = defu(options, defaults)

  return $fetch<T>(request, params)
}
