import payload_plugin_iwmgse80pC from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.7_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.27.4/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_tsAAqBLDKX from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._g5fsotuk3thhkspisoqmm6acx4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EJC9lKORSw from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._g5fsotuk3thhkspisoqmm6acx4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ebSfaqJ5f6 from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._g5fsotuk3thhkspisoqmm6acx4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_iRXeJozjlb from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._g5fsotuk3thhkspisoqmm6acx4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_nyn1HteAlP from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._g5fsotuk3thhkspisoqmm6acx4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lJ5KIDT5uW from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._g5fsotuk3thhkspisoqmm6acx4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_IE6vpqXSPI from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._g5fsotuk3thhkspisoqmm6acx4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_zoRs74g4un from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.7_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.27.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/.nuxt/components.plugin.mjs";
import prefetch_client_FBRUZ9u39g from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._g5fsotuk3thhkspisoqmm6acx4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_6zM5THrjJR from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.27.4/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_BR1H2PVdLC from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@nuxt+icon@1.8.2_magicast@0.3.5_rollup@4.27.4_vite@5.4.11_@types+node@22.10.0_terser@5.36.0___77pq5h5mow2cl6n3fvsoztucfa/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_Hm8Xmq2tqu from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.4_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_hEZWtRQuEs from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.4_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import primevue_plugin_egKpok8Auk from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/.nuxt/primevue-plugin.mjs";
import plugin_client_DTQrwClQ6n from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@primevue+nuxt-module@4.2.4_@babel+parser@7.26.2_magicast@0.3.5_rollup@4.27.4_vue@3.5.13_typescript@5.6.3_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import sentry_client_Q1RgAntGO5 from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@sentry+nuxt@8.41.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.28.0_@opentelemetry+api@1._ici6iovqcltgqje5i7oydxxo6q/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/.nuxt/sentry-client-config.mjs";
import toast_1MaKvwn9ob from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/plugins/toast.ts";
import zod_i18n_Dx4YU6TOgd from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/plugins/zod-i18n.ts";
import auth_e0FkfVuy48 from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/plugins/auth.ts";
import language_s7hCjuP47I from "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/plugins/language.ts";
export default [
  payload_plugin_iwmgse80pC,
  revive_payload_client_tsAAqBLDKX,
  unhead_EJC9lKORSw,
  router_ebSfaqJ5f6,
  payload_client_iRXeJozjlb,
  navigation_repaint_client_nyn1HteAlP,
  check_outdated_build_client_lJ5KIDT5uW,
  chunk_reload_client_IE6vpqXSPI,
  plugin_vue3_zoRs74g4un,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FBRUZ9u39g,
  plugin_6zM5THrjJR,
  plugin_BR1H2PVdLC,
  switch_locale_path_ssr_Hm8Xmq2tqu,
  i18n_hEZWtRQuEs,
  primevue_plugin_egKpok8Auk,
  plugin_client_DTQrwClQ6n,
  sentry_client_Q1RgAntGO5,
  sentry_client_config_o34nk2sJbg,
  toast_1MaKvwn9ob,
  zod_i18n_Dx4YU6TOgd,
  auth_e0FkfVuy48,
  language_s7hCjuP47I
]