
// @ts-nocheck


export const localeCodes =  [
  "is",
  "en",
  "uk",
  "pl"
]

export const localeLoaders = {
  "is": [{ key: "../node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/is.json", load: () => import("../node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/is.json" /* webpackChunkName: "locale__home_runner_work_contracts_frontend_v2_contracts_frontend_v2_node_modules__pnpm__64igloo_laboratory_43frontend_ui_layer_641_19_5__64babel_43parser_647_26_2_ioredis_645_4_1_magicast_640_3_5__viyckxlfxks433z5q3rw3wa2b4_node_modules__64igloo_laboratory_frontend_ui_layer_locales_is_json" */), cache: true },
{ key: "../src/locales/is.json", load: () => import("../src/locales/is.json" /* webpackChunkName: "locale__home_runner_work_contracts_frontend_v2_contracts_frontend_v2_src_locales_is_json" */), cache: true }],
  "en": [{ key: "../node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/en.json", load: () => import("../node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/en.json" /* webpackChunkName: "locale__home_runner_work_contracts_frontend_v2_contracts_frontend_v2_node_modules__pnpm__64igloo_laboratory_43frontend_ui_layer_641_19_5__64babel_43parser_647_26_2_ioredis_645_4_1_magicast_640_3_5__viyckxlfxks433z5q3rw3wa2b4_node_modules__64igloo_laboratory_frontend_ui_layer_locales_en_json" */), cache: true },
{ key: "../src/locales/en.json", load: () => import("../src/locales/en.json" /* webpackChunkName: "locale__home_runner_work_contracts_frontend_v2_contracts_frontend_v2_src_locales_en_json" */), cache: true }],
  "uk": [{ key: "../node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/uk.json", load: () => import("../node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/uk.json" /* webpackChunkName: "locale__home_runner_work_contracts_frontend_v2_contracts_frontend_v2_node_modules__pnpm__64igloo_laboratory_43frontend_ui_layer_641_19_5__64babel_43parser_647_26_2_ioredis_645_4_1_magicast_640_3_5__viyckxlfxks433z5q3rw3wa2b4_node_modules__64igloo_laboratory_frontend_ui_layer_locales_uk_json" */), cache: true },
{ key: "../src/locales/uk.json", load: () => import("../src/locales/uk.json" /* webpackChunkName: "locale__home_runner_work_contracts_frontend_v2_contracts_frontend_v2_src_locales_uk_json" */), cache: true }],
  "pl": [{ key: "../node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/pl.json", load: () => import("../node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/pl.json" /* webpackChunkName: "locale__home_runner_work_contracts_frontend_v2_contracts_frontend_v2_node_modules__pnpm__64igloo_laboratory_43frontend_ui_layer_641_19_5__64babel_43parser_647_26_2_ioredis_645_4_1_magicast_640_3_5__viyckxlfxks433z5q3rw3wa2b4_node_modules__64igloo_laboratory_frontend_ui_layer_locales_pl_json" */), cache: true },
{ key: "../src/locales/pl.json", load: () => import("../src/locales/pl.json" /* webpackChunkName: "locale__home_runner_work_contracts_frontend_v2_contracts_frontend_v2_src_locales_pl_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/i18n.config.ts?hash=6eb48093&config=1" /* webpackChunkName: "i18n_config_6eb48093" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "is",
      "language": "is-IS",
      "name": "Íslenska",
      "files": [
        "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/is.json",
        "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/locales/is.json"
      ]
    },
    {
      "code": "en",
      "language": "en-US",
      "name": "English",
      "files": [
        "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/en.json",
        "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/locales/en.json"
      ]
    },
    {
      "code": "uk",
      "language": "uk-UA",
      "name": "Українська",
      "files": [
        "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/uk.json",
        "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/locales/uk.json"
      ]
    },
    {
      "code": "pl",
      "language": "pl-PL",
      "name": "Polski",
      "files": [
        "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/pl.json",
        "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/locales/pl.json"
      ]
    }
  ],
  "defaultLocale": "is",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "is",
    "language": "is-IS",
    "name": "Íslenska",
    "files": [
      {
        "path": "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/is.json"
      },
      {
        "path": "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/locales/is.json"
      }
    ]
  },
  {
    "code": "en",
    "language": "en-US",
    "name": "English",
    "files": [
      {
        "path": "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/en.json"
      },
      {
        "path": "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/locales/en.json"
      }
    ]
  },
  {
    "code": "uk",
    "language": "uk-UA",
    "name": "Українська",
    "files": [
      {
        "path": "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/uk.json"
      },
      {
        "path": "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/locales/uk.json"
      }
    ]
  },
  {
    "code": "pl",
    "language": "pl-PL",
    "name": "Polski",
    "files": [
      {
        "path": "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/node_modules/.pnpm/@igloo-laboratory+frontend-ui-layer@1.19.5_@babel+parser@7.26.2_ioredis@5.4.1_magicast@0.3.5__viyckxlfxks433z5q3rw3wa2b4/node_modules/@igloo-laboratory/frontend-ui-layer/locales/pl.json"
      },
      {
        "path": "/home/runner/work/contracts-frontend-v2/contracts-frontend-v2/src/locales/pl.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
